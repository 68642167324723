<template>
	<div>
		<Breadcrumb titulo="CONSULTAS" :items="item" backgroundColor="#666" />
		<div class="container-fluid grid-container">
			<div class="cartao-dinamico-wrapper"
				 v-for="(cartao,index) in cartoesAtivos"
				 v-if="cartao.isVisible == null || cartao.isVisible()"
				 :key="index"
				 :class="cartao.authorize.some(item => {return item == dadosUsuarioAutenticado.perfil.toLowerCase()}) ? '' : 'd-none'">
				<router-link v-if="cartao.link?.trim()" :to="cartao.link" class="consultas-page-router-link">
					<cartao-dinamico :titulo="cartao.titulo"
									 :subtitulo="cartao.subtitulo"
									 :classe="cartao.classe"
									 :cor="cartao.cor"
									 :authorize="cartao.authorize"
									 :perfil="dadosUsuarioAutenticado.perfil.toLowerCase()"
									 :icon_bootstrap="cartao.icon_bootstrap" />
				</router-link>
				<cartao-dinamico v-else
								 :titulo="cartao.titulo"
								 :subtitulo="cartao.subtitulo"
								 :classe="cartao.classe"
								 :cor="cartao.cor"
								 :authorize="cartao.authorize"
								 :perfil="dadosUsuarioAutenticado.perfil.toLowerCase()"
								 :icon_bootstrap="cartao.icon_bootstrap"
								 @click="cartao.acao && cartao.acao()" />
			</div>
		</div>
		<component v-for="(item, index) in cartoesAtivos.filter(cartao => cartao.component)" :is="item.component" :key="`component-${index}`"></component>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
	import CartaoDinamico from '@/components/cartao/CartaoDinamico.vue';
	import axios from "axios";

	export default {
		name: 'ConsultasPage',

		components: {
			Breadcrumb,
			CartaoDinamico
		},

		data: function () {
			return {
				user: null,
				item: [
					{
						id: '1',
						classe: 'fas fa-search',
						texto: 'Consultas',
						link: '/consultas'
					}
				],
				canais: [],
				cartoes: [
					{
						titulo: 'Relatorios',
						subtitulo: 'Consulta de mensagens sem protocolos',
						classe: 'fas fa-file-alt',
						link: '/relatorios',
						cor: '#666666',
						icon_bootstrap: 'vazio',

						authorize: ['master', 'comercial', 'sdww', 'gestor', 'atendente']
					},
					{
						titulo: 'Atendimentos',
						subtitulo: 'Consulta de atendimentos por data, origem e status',
						classe: 'fa fa-table',
						link: '/consulta-atendimentos',
						cor: '#a1c9ff',
						icon_bootstrap: 'vazio',

						authorize: ['master', 'comercial', 'sdww', 'gestor', 'atendente']
					},
					{
						titulo: 'ATENDIMENTOS POR CLIENTE',
						subtitulo: 'Consulta de atendimentos do cliente no período',
						classe: 'fas fa-address-book',
						link: '/consulta-atendimentos-cliente',
						cor: '#8ddffe',
						icon_bootstrap: 'vazio',

						authorize: ['master', 'comercial', 'sdww', 'gestor', 'atendente']
					},
					{
						titulo: 'USO TELEFÔNICO',
						subtitulo: 'Consulta de métricas de uso dos ramais telefônicos',
						classe: 'fa fa-phone flip-h',
						link: '/uso-telefonico',
						cor: '#FD7E14',
						icon_bootstrap: 'vazio',
						authorize: ['master', 'comercial', 'sdww', 'gestor'],
						isVisible: () => {
							return this.canais?.includes(7);
						}
					},
					{
						titulo: 'MENSAGENS',
						subtitulo: 'Consulta de mensagens dos atendimentos',
						classe: '',
						link: '/consulta-mensagens',
						cor: '#f2f932',
						icon_bootstrap: 'chat-square-text-fill',

						authorize: ['master', 'comercial', 'sdww', 'gestor', 'atendente']
					},
					//{
					//	titulo: 'Grafico',
					//	subtitulo: 'Exibe graficos e tabelas',
					//	classe: 'fas fa-chart-line',
					//	link: '/graficos',
					//	cor: '#f2f932',
					//	icon_bootstrap: 'pie-chart-fill',

					//	authorize: ['master', 'comercial', 'sdww', 'gestor', 'atendente']
					//},
					{
						titulo: 'Relatorio de Pausas',
						subtitulo: 'Relatorio das pausas dos usuarios',
						classe: 'fa fa-user-clock',
						link: '/relatorio-usuarios-pausas',
						cor: '#3925d3',
						icon_bootstrap: 'vazio',

						authorize: ['master', 'comercial', 'sdww', 'gestor']
					},
					{
						titulo: 'Histórico de Campanhas',
						subtitulo: 'Consulta do histórico de campanhas transmitidas',
						classe: 'fa fa-bullhorn',
						link: '/consulta-historico-campanhas',
						cor: '#259cd3',
						icon_bootstrap: 'vazio',

						authorize: ['master', 'comercial', 'sdww', 'gestor']
					}
				],
				colorText: '#FFF',
				displayCard: ''
			}
		},

		computed: {
			...mapGetters({
				dadosUsuarioAutenticado: 'authentication/authDadosUsuario',
				getUsuarioPorId: 'usuarios/getUsuarioById'
			}),
			usuario: function () {
				if (this.dadosUsuarioAutenticado) {
					var ret = this.getUsuarioPorId(this.dadosUsuarioAutenticado.usuarioId);
					this.user = ret;

					return ret
				}
				return {};
			},
			consultasPersonalizadas() {
				return {
					ConciligMonitoriasAtendimento: {
						titulo: "Monitorias Atendimento",
						subtitulo: "Exportar relatório - Monitorias Atendimento",
						classe: "fas fa-file-download",
						cor: "#04AA6D",
						authorize: ["master", "comercial", "sdww", "gestor"],
						acao: () => this.$bvModal.show("concilig-exportar-monitoria-atendimento"),
						component: () => import("@/components/consultas/relatorios-personalizados/ConciligMonitoriasAtendimento")
					}
				};
			},
			cartoesPersonalizados() {
				return {
					// SharkData Local
					"localhost:3000": [
						this.consultasPersonalizadas.ConciligMonitoriasAtendimento
					],
					// Concilig BTG
					"concilig.sharkdata.com.br": [
						this.consultasPersonalizadas.ConciligMonitoriasAtendimento
					]
				};
			},
			cartoesAtivos: function () {
				return [
					...this.cartoes,
					...(this.cartoesPersonalizados[location.host] ?? [])
				];
			}
		},
		created() {
			axios.get("api/Atendimento/GetEnumAtendimentoOrigem").then(response => {
				this.canais = (response.data ?? []).map(item => item.Value);
			});
		}
	}
</script>

<style scoped>
	.grid-container {
		display: grid;
		grid-template-areas: 'main main main';
		grid-template-rows: 165px;
		grid-template-columns: 33% 33% 33%;
		margin-top: 50px;
	}

	.cartao-dinamico-wrapper {
		padding: 2%;
	}

	.consultas-page-router-link {
		text-decoration: none;
		color: inherit;
	}
</style>